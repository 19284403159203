.button_regresar_login {
  border: 1px solid #ddd;
  background-color: transparent;
  border-radius: 8px;
  color: #696969;
  transition: all .2s ease;
}

.button_regresar_login:hover {
  color: #e0324b;
  border: 1px solid #e0324b;
}