.container_productos_true_contact {
  display: grid;
  gap: 1rem;
  padding: 1rem 1.5rem;
  animation: productos_true_contact .5s ease;
}

@keyframes productos_true_contact {
  0% {
    transform: translateX(-80px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/******* Clases del componente NavbarProductos ********/

.content_title_productos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .2rem;
  padding-bottom: 1rem;
}

.content_buttons_agg {
  display: flex;
  gap: .8rem;
}

.title_productos {
  font-size: 1.7rem;
  color: #1E2022;
  font-weight: 600;
}

.button_regresar {
  border: 1px solid #ddd;
  background-color: transparent;
  border-radius: 8px;
  color: #555e6d;
  transition: all .2s ease;
  padding: 2px 5px;
  display: grid;
  place-items: center;
}

.button_regresar:hover {
  color: #e0324b;
  border: 1px solid #e0324b;
}

.button_product_agg {
  background-color: #e0324b;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  font-size: .9rem;
  padding: 7px 12px;
  transition: all .3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button_product_agg:hover {
  background-color: #a01e31;
}

/********************/

/******* Clases del componente HeaderProductos ********/

.content_header_productos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .2rem;
  padding-bottom: .5rem;
}

.filtros_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content_filtros {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
}

.title_filtros_productos {
  font-size: 1rem;
  color: #1E2022;
  padding-right: 1rem;
  padding-top: 4px;
}

.filtro_productos {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 4px 12px;
  cursor: pointer;
  transition: all .2s ease;
  gap: .5rem;
}

.filtro_productos:hover {
  border: 1px solid #e0324b;
}

.span_title {
  color: #6c7184;
  font-size: .9rem;
}

.span_filtro {
  color: #1E2022;
  font-weight: 500;
  font-size: .9rem;
}

.options_filtros {
  display: none;
}

.show_ {
  position: absolute;
  top: 37px;
  width: 250px;
  max-height: 400px;
  min-width: 200px;
  display: grid;
  background-color: #fff;
  padding: .5rem;
  border-radius: 6px;
  border: 1px solid #ebeaf2;
  box-shadow: 0 7px 10px 0 #d7d5e2;
  overflow: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.button_filtro_producto {
  background-color: transparent;
  padding: 7px 20px;
  text-align: left;
  border-radius: 6px;
  transition: all .1s ease;
  border: none;
  font-size: .9rem;
}

.button_filtro_producto:hover {
  background-color: #e0324b;
  color: #fff;
}

.content_search {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input_buscar_producto {
  width: 275px;
  padding: 5px 9px;
  border-radius: 6px 0 0 6px;
  border: 1px solid #ddd; 
  transition: all .2s ease;
}

.input_buscar_producto:hover {
  border: 1px solid #e0324b;
}

.input_buscar_producto:focus {
  outline: none;
  border: 1px solid #e0324b;
}

.button_search {
  background-color: #e0324b;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0 6px 6px 0;
  transition: all .2s ease;
}

.button_search:hover {
  background-color: #a01e31;
}

/*****************/

/******* Clases del componente ListaProductos ********/

.section_table {
  width: 100%;
}

.tabla_productos {
  width: 100%;
  box-shadow: 0 7px 12px 0 #d7d5e2;
  border-radius: 8px;
  overflow: hidden;
}

.thead_lista_productos {
  background-color: #e0324c25;
}

.th_lista {
  height: 60px;
}

th {
  font-weight: 500;
  padding: .5rem 1rem;
}

.content_img_producto {
  width: 70px;
  height: 105px;
  cursor: pointer;
}

.img_producto {
  width: 70px;
  background-color: #fff;
}

.td_list {
  border: 1px solid #ddd;
  transition: all .2s ease;
}

td {
  padding: .5rem 1rem;
  vertical-align: top;
}

.buttons_action_productos {
  display: none;
  transition: all .2s ease;
}

.td_list:hover .buttons_action_productos{
  display: flex;
  gap: .5rem;
}
.content_buttons_action {
  display: flex;
  gap: .25rem;
}
.action_button{
  color: #6c7184;
  transition: all .2s ease;
  font-size: 1.05rem;
}

.action_button:hover {
  color: #e0324b; 
}

.id_producto {
  color: #1E2022;
  font-size: 1.05rem;
}

.categoria_ {
  font-size: .9rem;
}

.disponible {
  font-weight: 700;
  color: #09cc09;
  font-size: .9rem;
}

.no_disponible {
  font-weight: 700;
  color: #6c7184;
  font-size: .9rem;
}

.content_td_name {
  width: 320px;
}

.name_td {
  font-weight: 600;
  text-transform: uppercase;
  color: #e0324b;
  cursor: pointer;
  font-size: 1rem;
  width: max-content;
  transition: all .2s ease;
}

.name_td:hover {
  color: #a01e31;
  text-decoration: underline;
}

.icon_estadistica {
  color: #e0324b;
  cursor: pointer;
  transition: all .2s ease;
}

.icon_estadistica:hover {
  color: #a01e31;
} 


/***********  Estilos de ProductAdd  ************/

.container_productos_true_contact_add {
  display: grid;
  padding: 1rem 1.5rem;
  animation: productos_true_contact .5s ease;
} 

.content_title_productos_form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .2rem;
}

.container_add_productos {
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  position: relative;
}

.buttons_navigation {
  position: relative;
  display: flex;
  gap: 1rem;
}

.button_navigation_form {
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all .2s ease;
  padding: .5rem .5rem;
  position: relative;
}

.border_button_navigation_form {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #e0324b;
  transition: left .3s ease, width .3s ease;
  width: 0;
}

.button_navigation_form:hover {
  color: #e0324b;
}

.button_navigation_form_active {
  color: #e0324b;
}


/* FormProductos */

.contain_form_productos {
  width: 100%;
  display: grid;
  place-items: center;
  padding: 1rem 0;
  position: relative;
}

.form_productos {
  display: grid;
  place-items: center;
  align-items: center;
  width: 800px;
  max-height: 100%;
  padding: .5rem 2rem;
}

.content_form_producto {
  width: 100%;
  display: grid;
  gap: 1.5rem;
}

.title_form_producto {
  font-size: 1.45rem;
  text-align: left;
  padding: 1rem 0 0 0;
}

.content_doble_columna {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.content_nombre-marca-descripcion {
  display: grid;
  gap: 2rem;
}

.content_input_producto {
  display: grid;
  position: relative;
}

.label_producto {
  font-size: 400;
  text-transform: uppercase;
  color: #1E2022;
  font-size: .8rem;
}

.input_producto {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 7px 11px;
  transition: all .2s ease;
  color: #1E2022;
}

.input_descrip {
  min-height: 50px;
}

.input_producto:hover {
  border: 1px solid #e0324b;
}

.input_producto:focus {
  outline: none;
  border: 1px solid #e0324b;
}

.label_imagen_producto {
  color: #e0324b;
  border: 1px solid #e0324b;
  border-radius: 8px;
  padding: 7px 12px;
  cursor: pointer;
  display: inline-block;
  transition: all .2s ease;
}

.input_imagen_producto {
  display: none;
}

.label_imagen_producto:hover {
  border: 1px solid #a01e31;
  color: #a01e31;
}

.content_form_imagen {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select_productos {
  display: none;
}

.show_salect_productos {
  position: absolute;
  max-height: 280px;
  top: 4.4rem;
  width: 100%;
  display: grid;
  background-color: #fff;
  padding: .5rem;
  border-radius: 6px;
  border: 1px solid #ebeaf2;
  box-shadow: 0 7px 10px 0 #d7d5e2;
  z-index: 100;
  overflow: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.icon_chevron {
  transform: rotate(0deg);
  transition: transform .2s ease;
}

.rotate {
  transform: rotate(90deg);
  transition: transform .2s ease;
}

.button_select_productos {
  background-color: transparent;
  padding: 7px 20px;
  text-align: left;
  border-radius: 6px;
  transition: all .1s ease;
  border: none;
  cursor: pointer;
}

.button_select_productos:hover {
  background-color: #e0324b;
  color: #fff;
}

.label_producto_checkbox {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.checkbox_producto:checked + .label_producto_checkbox {
  background-color: #e0324b;
}

.checkbox_producto {
  display: none;
}

.checkbox_producto:checked + .label_producto_checkbox::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.content_envios_productos {
  padding-top: 6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.lista_imagenes_post {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 15px;
  position: relative;
}

.button_eliminar_imagen_post {
  background-color: #e0324b;
  border: none;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  font-weight: 600;
  transition: all .2s ease;
}

.button_eliminar_imagen_post:hover {
  background-color: #a01e31;
}


/*********************************/


/**************** Estilos EnviarExcel *****************/

.content_buttons_excel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.button_enviar_excel {
  background-color: #e0324b;
  border: none;
  border-radius: 6px;
  width: 80px;
  height: 35px;
  color: #fff;
  transition: all .2s ease;
}

.button_enviar_excel:hover {
  background-color: #a01e31;
}

.button_cancelar_excel {
  background-color: #6c7184;
  border: none;
  border-radius: 6px;
  width: 80px;
  height: 35px;
  color: #fff;
  transition: all .2s ease;
}

.button_cancelar_excel:hover {
  background-color: #565a69;
}

.content_info_excel {
  display: grid;
  place-items: center;
  line-height: 1.1;
}

.title_generar_excel {
  font-size: 1.2rem;
}

.name_excel {
  text-align: center;
  padding-top: 2rem;
}

.animation_modal {
  animation: animation-modal .5s ease;
}

@keyframes animation-modal {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


/********* Estilos ProductosDetail ***********/

.contain_producto_detail{
  display: grid;
  place-items: center;
  padding-top: 2rem;
}







/******  Responsive  *******/

@media (max-width: 1110px) {
  .content_header_productos {
    display: grid;
    place-content: center;
    gap: 1rem;
  }
  .input_buscar_producto {
    width: 150px;
    
  }
}

@media (max-width: 920px) {
  /* .content_title_productos {
    width: 900px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  } */
  .content_td_name {
    width: 150px;
  }
  .name_td {
    width: 150px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .td_list:hover .buttons_action_productos{
    display: grid;
    line-height: .2;
  }
}