.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: .5rem;
  height: 140px;
}

.img_loading_list {
  width: 60px;
  animation: spin 1s linear infinite;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.loading_spinner_screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: .5rem;
  height: 100vh;
  width: 100%;
  background-color: #1411118e;
  z-index: 200;
  position: fixed;
  top: -.5px;
  left: 0;
}

.p_cargando {
  font-size: 1rem;
}


.img_loading_list_screen {
  width: 80px;
  animation: spin 1s linear infinite;
}

.spinner_screen {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}