@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */

:root{
  --primary-100:#e0324b;
  --primary-200:#a01e31;
  --primary-250:#630000;
  --primary-300:#F0F5F9;
  --accent-100:#788189;
  --accent-200:#e1e4e6;
  --text-100:#1E2022;
  --text-200:#52616B;
  --bg-100:#EEEBDD;
  --bg-200:#DFD3C3;
  --bg-300:#C7B198;
  --sombra-cajas-dashboard : 0 2px 10px -1px rgb(0 0 0 / 0.1), 0 2px 7px -2px rgb(0 0 0 / 0.1);
  --sombra-cajas-blancas : inset 0px -16px 3px 0px rgba(255,255,255,1);

}

/********** Animacion loading **********/


@keyframes slideDown {
  0% {
    transform: translateY(100%);
    
  }
  100% {
    transform: translateY(0);
    
  }
}

.home{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  font-family: "Lato";
  animation: slideDown 1.5s ease-out;
}

.mensaje_home {
  width: 100%;
  height: 18px;
  background-color: var(--primary-100);
  position: fixed;
  z-index: 100;
}

.p_mensaje {
  font-size: .75rem;
  font-weight: 600;
  color: var(--primary-300);
  text-align: center;
  text-transform: uppercase;
}

.navbar_home {
  position: fixed;
  top: 18px;
  z-index: 100;
  background-color: #fff;
  height: 55px;
  width: 100%;
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--sombra-cajas-dashboard);
  transition: all .3s ease;
}

.list_navbar{
  display: flex;
  padding: 15px 32px 0 0;
}

.ul_navbar{
  display: flex;
  list-style: none;
  gap: 2rem;
}

.li_navbar{
  list-style: none;
  padding: 5px;
  color: #52616B;
  font-weight: 600;
  cursor: pointer;
  transition: color .3s ease;
  font-size: .9rem;
}

.li_navbar:hover{
  color: var(--primary-100);
}

.active_navbar{
  color: var(--primary-100);
  border-bottom: #e0324b 2px solid;
}

.logo_navbar{
  width: max-content;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo{
  width: 170px;
}
.logo_button{
  cursor: pointer;
}

.list_buttons_navbar{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding-right: .7rem;
}

.buttons_navbar{
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  transition: color .3s ease;
}

.buttons_navbar:hover{
  color: var(--primary-100);
}

.button_navbar_active {
  color: var(--primary-100);
}


.button_login{
  background-color: var(--primary-100);
  color: #fff;
  padding: 5px 12px;
  border-radius: 8px;
  overflow: hidden;
  font-weight: 600;
  font-size: .9rem;
  cursor: pointer;
  transition: all .3s ease;
}

.button_login:hover{
  color: #fff;
  background-color: var(--primary-200);
}

.content_wsapp {
  position: fixed;
  z-index: 9999;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
}

.img_wapp {
  width: 60px;
  cursor: pointer;
  z-index: 9999;
  
}

.p_wapp_oculto {
  animation: ws_animation_ocultar .3s ease;
  background-color: #4bc75a;
  color: #fff;
  padding: 8px 8px;
  border-radius: 10px 0 0 10px;
  font-size: 1rem;
  cursor: pointer;
  position: absolute;
  width: 155px;
  right: 3rem;
  opacity: 0;
}

.p_wapp {
  background-color: #4bc75a;
  color: #fff;
  padding: 8px 8px;
  border-radius: 10px 0 0 10px;
  font-size: 1rem;
  cursor: pointer;
  position: absolute;
  width: 155px;
  right: 3rem;
  animation: ws_animation .3s ease-in-out;
}

@keyframes ws_animation {
  0% {
    transform: translateX(20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes ws_animation_ocultar {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(20px);
    opacity: 0;
  }
}



/******   Estilos de Home Inicial   ******/

.home_inicial{
  height: 100vh;
  width: 1100px;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  
}

/* .section-text {

} */

.hm{
  width: 100%;
  animation: title_home 1.6s ease-in;
}
.mh{
  
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: start;
  animation: llanta_home 1.6s ease-in;
}

@keyframes title_home {
  0% {
    transform: translateX(-100px);
    /* opacity: .5; */
  }
  100% {
    transform: translateX(0);
    /* opacity: 1; */
  }
}

@keyframes llanta_home {
  0% {
    transform: translateX(100px);
    /* opacity: .5; */
  }
  100% {
    transform: translateX(0);
    /* opacity: 1; */
  }
}

.tittle-home{
  font-size: 4.5rem;
  font-weight: 700;
  color: var(--text-100);
  letter-spacing: -2px;
  line-height: 1;
}

.llantas_home{
  width: 400px;
}

.text_home_span{
  color: var(--primary-100);
}
.parrafo_home{
  font-size: 1.5rem;
  font-weight: 600;
  color: #1E2022;
  padding-left: 4px;
  padding-bottom: 2.5rem;
}

.content_button_hinicial {
  display: flex;
}

.button_home{
  background-color: var(--primary-100);
  color: #FFF;
  padding: 10px 10px;
  font-size: 1.2rem;
  border-radius: 6px;
  margin: 4px;
  transition: all .3s ease;
}
.button_home:hover{
  background-color: var(--primary-200);
}




/**************   Productos Destacados   **************/


.productos_destacados{
  padding-top: 6rem;
  height: 100vh;
  width: 1200px;
}

.title_products {
  color: var(--text-100);
  font-size: 2rem;
  font-weight: 600;
  padding-left: 3rem;
}

.products_list{
  display: grid;
  width: 1200px;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  place-items: center;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
}

.card_product{
  height: 550px;
  width: 380px;
  border: #eeeeee 1px solid;
  border-radius: 8px;
  box-shadow: var(--sombra-cajas-dashboard);
}

.card_product:hover img {
  transform: scale(1.1);
}
.card_product:hover .img_container {
  background-color: #cccccc;
  overflow: hidden;
  z-index: 100;
}

.img_container{
  width: 100%;
  height: 300px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0 0;
}

.img_container img {
  transition: transform .4s ease;
}

.img_product{
  width: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.container_product {
  display: grid;
  grid-template-rows: 110px 1fr; 
  padding: 0 25px;
}

.details_container{
  position: relative;
}


.title_product{
  font-size: 1.6rem;
  color: var(--text-100);
  
}

.brand_product{
  font-size: 1.8rem;
  font-weight: 900;
  text-transform: uppercase;
}

.category_product{
  font-size: 1.25rem;
  color: var(--text-200);
}

.price_product{
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--text-100);
}

.buttoms_action{
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.comprar_producto {
  background-color: var(--primary-100);
  color: var(--primary-300);
  padding: 13px 12px;
  border-radius: 8px;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease;
}

.comprar_producto:hover {
  background-color: var(--primary-200);
}

.detalles_producto {
  background-color: #6e6e6e;
  color: var(--primary-300);
  padding: 13px 12px;
  border-radius: 8px;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease;
}

.detalles_producto:hover {
  background-color: #414141;
}



/************ DISTRIBUCIONES LLANTAS Y MARCAS ************/


.section_distribuciones{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5.5rem;
  height: 100vh;
  padding-top: 4rem;
}

.distribuciones_container {
  display: grid;
  place-items: center;
}

.title_distri {
  font-size: 3.3rem;
  font-weight: 600;
  color: var(--text-100);
}

.p_distri {
  font-size: 1.5rem;
}

.statistics_container {
  width: 1200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
}

.content_statistics {
  display: grid;
  place-items: center;
}

.title_statistics {
  font-size: 3rem;
  font-weight: 600;
  color: var(--primary-100);
}

.p_descriptions {
  font-size: 1.25rem;
}

.marcas {
  display: grid;
  place-items: center;
  
}

.container_marcas {
  width: 1200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  place-items: center;
}

.marcas_content {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.title_marcas {
  font-size: 1.5rem;
}

.img_marcas {
  width: 150px;
}




/****************  FOOTER  *****************/

.footer_container {
  display: flex;
  justify-content: space-around;
  align-items: start;
  width: 99.6vw;
  background-color: var(--primary-100);
  position: relative;
  padding: 3rem 0;
}

/* .info_legal_container {
  
} */


.soporte_container {
  display: flex;
  flex-direction: column;
}

.title_info {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--primary-300);
  padding: 1rem 0;

}

.info_legal {
  display: flex;
  flex-direction: column;
  gap: .75rem;
}

.options {
  color: var(--primary-300);
  display: flex;
  font-size: 1.1rem;
  transition: all .3s ease;
}

.options:hover {
  color: var(--bg-300);
}

.icon_container {
  display: flex;
  gap: .75rem;
}

.options_icon {
  color: var(--primary-300);
  display: flex;
  font-size: 1.1rem;
  transition: all .3s ease;
}

.options_icon:hover {
  color: var(--bg-300);
}

.social {
  padding-top: 1.2rem;
}

.logo_footer_container {
  width: 350px;
  display: grid;
  place-items: center;
}

.logo_footer {
  width: 350px;
  padding-top: 3rem;
}

.p_logo {
  color: var(--primary-300);
  font-size: 1rem;
}


/************** MODALS LLANTAS Y CONTACTANOS **************/

.modal_llantas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
}

.open_modal_llantas {
  width: 100vw;
  min-height: 100vh;
  z-index: 200;
  background-color: #1411118e;
  display: grid;
  place-items: center;
  padding-top: 20px;
}
.open_modal_llantas_ {
  width: 100vw;
  min-height: 100vh;
  z-index: 200;
  display: grid;
  place-items: center;
  padding-top: 20px;
}

.modal_content_llantas {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
}

.title_modal_llantas {
  font-size: 1.4rem;
  color: var(--text-100);
  font-weight: 600;
  text-align: center;
  padding-bottom: 1rem;
}

.vehiculo_seleccionar_llantas {
  border: 1px solid var(--accent-100);
  border-radius: 8px;
  width: 160px;
  height: 160px;
  transition: all .3s ease;
  cursor: pointer;
  display: grid;
  place-items: center;
  justify-content: center;
}

.container_imagen_llantas {
  width: 80px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagen_vehiculo_llantas {
  transition: transform .3s ease;
  width: 80px;
  height: 70px;
}

.container_name_vehiculo_llantas {
  display: flex;
  justify-content: center;
  width: 180px;
  padding: 10px;
}

.name_vehiculo_llantas {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: .95rem;
}

.vehiculo_seleccionar_llantas:hover {
  border: 1px solid #e0324b;
  background-color: #e0324c0c;
}

.vehiculo_seleccionar_llantas:active {
  border: 1px solid #e0324b;
  background-color: #e0324c0c;
}

.vehiculo_seleccionar_llantas:hover .name_vehiculo_llantas {
  color: #e0324b;
}

.vehiculo_seleccionar_llantas:hover .imagen_vehiculo_llantas {
  transform: scale(1.20);
}



.options_modal_llantas {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.buttom_option_modal{
  background-color: var(--primary-100);
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding: 10px 10px;
  border-radius: 6px;
  color: var(--primary-300);
  transition: all .3s ease;
}

.buttom_option_modal:hover {
  background-color: var(--primary-200);
}

.closed_modal_llantas {
  position: absolute;
  padding: 3.5px 9.5px;
  border-radius: 50%;
  background-color: #e0324b;
  color: var(--primary-300);
  top: 3px;
  right: 6px;
  display: grid;
  place-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  transition: all .3s ease;
}

.closed_modal_llantas:hover {
  background-color: #800101;
}

/************** MODAL CONTENEDOR DE LOS TIPOS DE VEHICULOS ****************/

.modal_content_tipo_vehiculo {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  position: relative;
}

.opciones_tipo_vehiculo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.vehiculo_seleccionar {
  border: 1px solid var(--accent-100);
  border-radius: 8px;
  width: 160px;
  height: 160px;
  transition: all .3s ease;
  cursor: pointer;
  display: grid;
  place-items: center;
}


.container_imagen {
  width: 110px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagen_vehiculo {
  width: 110px;
  height: 100px;
  transition: transform .3s ease;
}

.container_name_vehiculo {
  display: flex;
  justify-content: center;
  width: 160px;
  padding: 5px;
}

.name_vehiculo {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: .95rem;
}

.vehiculo_seleccionar:hover {
  border: 1px solid #e0324b;
  background-color: #e0324c0c;
}

.vehiculo_seleccionar:active {
  border: 1px solid #e0324b;
  background-color: #e0324c0c;
}

.vehiculo_seleccionar:hover .name_vehiculo {
  color: #e0324b;
}

.vehiculo_seleccionar:hover .imagen_vehiculo {
  transform: scale(1.20);
}

.modal_oculto {
  display: none;
}

.hamburger_menu {
  display: none;
}


@media (max-width: 1280px) {
  .home{
    width: 1024px;
  }

  .navbar_home {
    padding: 0 1rem;
    height: 50px;
  }

  .list_navbar{
    display: flex;
    padding: 15px 0 0 0;
  }

  .li_navbar {
    font-size: .9rem;
  }

  .ul_navbar{
    display: flex;
    list-style: none;
    gap: .5rem;
  }

  .logo{
    width: 140px;
  }
  .list_buttons_navbar{
    gap: 1rem;
    padding-right: .5rem;
  }

  .buttons_navbar{
    padding: 0;
  }
  .button_login{
    padding: 3px 9px;
    font-size: .9rem;
  }

  .home_inicial {
    width: 1024px;
    padding: 0 1rem;
  }

  .productos_destacados {
    width: 1024px;
    height: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  
  
  .products_list {
    width: 1024px;
    height: 100%;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    align-items: center;
    padding-top: 2rem;
  }
  
  .section_distribuciones {
    width: 900px;
    height: 100%;
    padding-top: 6rem;
    padding-bottom: 4rem;
  }

  .statistics_container {
    width: 900px;
  }

  .title_distri {
    font-size: 2.3rem;
  }

  .title_statistics {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--primary-100);
  }
  
  .p_descriptions {
    font-size: 1.1rem;
  }

  .container_marcas {
    width: 800px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  }

  .footer_container {
    width: 99vw;
  }
}


@media (max-width: 1024px) {
  .home {
    width: 768px;
  }

  .home_inicial {
    width: 768px;
    padding: 0 1rem;
  }

  .llantas_home{
    width: 350px;
  }

  .tittle-home{
    font-size: 3.8rem;
  }

  .parrafo_home{
    font-size: 1.35rem;
    padding-bottom: 1.5rem;
  }

  .productos_destacados {
    width: 768px;
    height: 100%;
    padding-bottom: 2rem;
  }
  .title_products {
    text-align: center;
    padding-left: 0;
  }
  .products_list {
    width: 768px;
    height: 100%;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    place-items: center;
    gap: 1rem;
    padding-top: 1.5rem;
  }

  .section_distribuciones {
    width: 768px;
    padding-top: 6rem;
    padding-bottom: 2rem;
  }

  .statistics_container {
    width: 700px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  }

  .title_distri {
    font-size: 2.3rem;
  }
  .p_distri {
    font-size: 1.2rem;
  }

  .container_marcas {
    width: 700px;
    display: grid;
  }

}


@media (max-width: 870px) {
  .li_navbar {
    font-size: .8rem;
  }

  .navbar_home {
    padding: 0 ;
  }

  .title_distri {
    font-size: 1.6rem;
    width: 400px;
    text-align: center;
  }
  
  .productos_destacados {
    height: 100%;
    }

  .products_list {
    height: 100%;
    padding-bottom: 2rem;
  }

  .container_marcas {
    width: 500px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .footer_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  .info_legal_container {
    width: 230px;
    display: grid;
    place-items: start;
  }

  .div_soporte {
    width: 230px;   
    display: grid;
    place-items: start;
  }

}

@keyframes menu {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes menuClosed {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-15%);
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .home {
    width: 600px;
  }

  .home_inicial {
    width: 600px;
  }

  .navbar_home {
    pad: 0 1rem;
  }

  .list_navbar {
    display: none;
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    background-color: #fff;
    flex-direction: column;
    padding: 1rem 0;
    box-shadow: var(--sombra-cajas-dashboard);
    animation: menuCLosed .3s ease;
  }
  
  .list_navbar.open {
    display: flex;
    justify-content: center;
    animation: menu .3s ease;
  }

  .ul_navbar {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 100%;
  }

  .li_navbar {
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
  }

  .hamburger_menu {
    display: block;
    width: 30px;
    height: 40px;
    background-color: transparent;
    cursor: pointer;
  }

  .hamburger {
    display: grid;
    place-items: center;
    width: 20px;
    height: 3px;
    background-color: #333;
    position: relative;
    transition: all .3s ease;
    top: 18px;
  }

  .hamburger::before,
  .hamburger::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 3px;
    background-color: #333;
    transition: all .3s ease;
  }

  .hamburger::before {
    top: -8px;
  }

  .hamburger::after {
    top: 8px;
  }

  .hamburger.open {
    background-color: transparent;
  }

  .hamburger.open::before {
    top: 0;
    transform: rotate(45deg);
    background-color: #e0324b;
  }

  .hamburger.open::after {
    top: 0;
    transform: rotate(-45deg);
    background-color: #e0324b;
  }

  .button_login{
    padding: 3px 9px;
    font-size: .8rem;
  }

  .content_button_hinicial {
    display: grid;

  }

  .button_home {
    width: max-content;
    font-size: 1.1rem;
  }

  .llantas_home{
    width: 330px;
  }

  .tittle-home{
    font-size: 3rem;
  }

  .parrafo_home{
    font-size: 1.2rem;
    padding-bottom: 1.5rem;
  }

  .productos_destacados {
    width:600px;
    height: 100%;
    padding-bottom: 2rem;
  }
  .title_products {
    text-align: center;
    padding-left: 0;
  }
  .products_list {
    width: 600px;
    height: 100%;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    place-items: center;
    gap: 1rem;
    padding-top: 1.5rem;
  }

  .section_distribuciones {
    width: 500px;
    padding-top: 6rem;
    padding-bottom: 2rem;
  }

  .statistics_container {
    width: 450px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  }

  .title_distri {
    font-size: 1.5rem;
    width: 400px;
    text-align: center;
  }
  .p_distri {
    font-size: 1.1rem;
  }

  .container_marcas {
    width: 400px;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }

  .modal_content_tipo_vehiculo {
    padding: 2rem;
    gap: 2rem;
  }

  .modal_content_llantas{
    padding: 2rem;
    gap: 2rem;
  }

  .container_imagen_llantas {
    width: 70px;
    height: 60px;
  }

  .imagen_vehiculo_llantas {
    width: 70px;
    height: 60px;
  }

  .title_modal_llantas {
    font-size: 1.3rem;
  }

}

@media (max-width: 600px) {
  .home {
    width: 320px;
  }

  .home_inicial {
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding-top: 4rem;
  }

  .llantas_home{
    width: 160px;
  }

  .tittle-home{
    font-size: 2.5rem;
    text-align: center;
  }
  
  .parrafo_home{
    font-size: 1.2rem;
    text-align: center;
    padding-bottom: 1rem;
  }

  .mh{  
    justify-content: center;
  }

  .content_button_hinicial {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .button_home {
    font-size: .9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .25rem;
  }
  
  .productos_destacados {
    padding-top: 0;
    width:320px;
    height: 100%;
    padding-bottom: 2rem;
  }
  .title_products {
    text-align: center;
    font-size: 1.5rem;
    padding-left: 0;
  }
  .products_list {
    width: 320px;
    height: 100%;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    place-items: center;
    gap: 1rem;
    padding-top: 1.5rem;
  }

  .card_product{
    height: 550px;
    width: 300px;
    border: #eeeeee 1px solid;
    border-radius: 8px;
    box-shadow: var(--sombra-cajas-dashboard);
  }
  

  .container_product {
    display: grid;
    grid-template-rows: 110px 1fr; 
    padding: 0 25px;
  }
  
  .details_container{
    position: relative;
  }
  
  
  .title_product{
    font-size: 1.4rem;
    color: var(--text-100);
    
  }
  
  .brand_product{
    font-size: 1.5rem;
    font-weight: 900;
    text-transform: uppercase;
  }
  
  .category_product{
    font-size: 1.15rem;
    color: var(--text-200);
  }
  
  .buttoms_action{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  
  .comprar_producto {
    padding: 10px 10px;
    font-size: .9rem;
  }
  
  .comprar_producto:hover {
    background-color: var(--primary-200);
  }
  
  .detalles_producto {
    padding: 10px 10px;
    font-size: .9rem;
  }

  .section_distribuciones {
    width: 150px;
    height: 100%;
    padding-top: 5rem;
    padding-bottom: 2rem;
  }

  .statistics_container {
    width: 150px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .title_distri {
    font-size: 1.3rem;
    width: 150px;
  }
  .p_distri {
    text-align: center;
    width: 150px;
    font-size: .9rem
  }

  .title_statistics {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--primary-100);
  }
  
  .p_descriptions {
    font-size: .8rem;
  }

  .container_marcas {
    width: 40px;
    grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
  }

  .title_marcas {
    font-size: 1.1rem;
  }

  .img_marcas {
    width: 100px;
  }

  .logo_footer_container {
    width: 200px;
  }

  .logo_footer {
    width: 200px;
  }

  .footer_container {
    width: 98.5vw;
  }

  .title_info {
    font-size: 1.4rem;
  }

  .options {
    font-size: 1rem;
  }

  .p_logo {
    font-size: .9rem;
  }

  .title_modal_llantas {
    font-size: 1rem;
    width: 180px;
  }

  .options_modal_llantas {
    gap: .5rem;
    flex-wrap: wrap;
  }

  .vehiculo_seleccionar {
    width: 110px;
    height: 110px;
  }

  .container_imagen {
    width: 70px;
    height: 60px;
  }

  .imagen_vehiculo {
    width: 70px;
    height: 60px;
  }

  .vehiculo_seleccionar_llantas {
    width: 90px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
  }

  .container_imagen_llantas {
    width: 40px;
    height: 30px;
  }

  .imagen_vehiculo_llantas {
    width: 40px;
    height: 30px;
  }


  .container_name_vehiculo {
    width: 110px;
    padding: 5px;
  }
  
  .name_vehiculo {
    font-size: .7rem;
  }

  .container_name_vehiculo_llantas {
    width: 120px;
    padding: 5px;
  }
  
  .name_vehiculo_llantas {
    font-size: .7rem;
  }

  .modal_content_tipo_vehiculo {
    padding: 1rem;
    gap: 1rem;
  }

  .content_title_modal_llantas {
    display: flex;
    justify-content: center;
  }

  .modal_content_llantas {
    padding: 1rem;
    gap: 1rem;
    width: 300px;
  }

}