/* src/components/Calendario.css */

.calendar_open {
  animation: open_calendar .3s ease;
  transition: all .3s ease;
}

.calendar {
  position: absolute;
  top: 65px;
  left: 0;
  background-color: white;
  border-radius: 8px;
  padding: 1rem 2rem;
  width: 93%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-nav {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.calendar-nav:hover {
  color: #e0324b;
}

.calendar-month {
  font-size: 18px;
  font-weight: bold;
}

.calendar-weekdays {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 15px;
}

.calendar-week {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.calendar-day {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.calendar-day.outside-month {
  color: #c7c7c7;
}

.calendar-day.selected {
  background-color: #e0324c25;
  border: .5px solid #e0324b;
}

.calendar-day:hover {
  background-color: #e0324c25;
}

.button_send_calendario {
  background-color: #e0324b;
  padding: 7px 15px;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-weight: 600;
  transition: all .2s ease;
  cursor: pointer;
}

.button_send_calendario:hover {
  background-color: #a82034;
}

@keyframes open_calendar {
  0% {
    transform: translateY(-15%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}