.content_img_vehiculo{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
}

.vehiculo_imagen_add_list {
  width: 90px;
  height: 100px;
  object-fit: contain;
}

.button_agregar_img {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--primary-100);
  background-color: #fff;
  color: #e0324b;
  border-radius: 8px;
  padding: 7px 10px;
  cursor: pointer;
  transition: all .2s ease;
}

.button_agregar_img:hover {
  background-color: #e0324b;
  color: #fff;
}

.content_nav_vehiculos {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.button_search_vehiculo {
  background-color: #e0324b;
  height: 37px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0 6px 6px 0;
  transition: all .2s ease;
}

.filtros_container_vehiculos {
  display: flex;
  justify-content: start;
  align-items: center;
  padding-top: 1rem;
}

.label_imagen_producto_vehiculo {
  color: #fff;
  background-color: #e0324b;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
  display: inline-block;
  transition: all .2s ease;
  font-size: .9rem;
  transition: all .2s ease;
}

.label_imagen_producto_vehiculo:hover {
  background-color: #a01e31;
}

.content_info_imagen_vehiculo {
  display: grid;
  place-items: center;
  gap: 1rem;
}

.preview_img {
  width: 200px;
}

.effect_select {
  animation: effect-select 1.2s ease;
}

@keyframes effect-select {
  0% {
    background-color: #e0324c25;
  }
  100% {
    background-color: inherit;
  }
}