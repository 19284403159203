.container_cart {
  width: 500px;
  border: .5px solid #e2e2e2;
  border-radius: 6px;
  box-shadow:  0 5px 8px -1px rgb(0 0 0 / 0.1), 0 0px 7px -2px rgb(0 0 0 / 0.1);
  position: absolute;
  top: 3.5rem;
  right: 10rem;
  background-color: #fff;
  padding: 1.5rem 1rem;
  
  transition: all .3s ease;
}

.cart_open {
  animation: cart .3s ease;
  transition: all .3s ease;
}

.cart_closed {
  animation: cartClosed .3s ease;
  transition: all .3s ease;
}

.title_cart {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1E2022;
  padding: 0 0 1.5rem 1rem ;
}

.carrito_vacio {
  text-align: center;
  font-size: 1rem;
}

.card_cart {
  max-height: 400px;
  overflow: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.content_title_carrito_info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.content_info_producto_cart {
  display: flex;
  gap: .5rem;
  padding-bottom: 1rem;
  
}

.content_img_cart {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.img_cart {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.info_producto {
  width: 240px;
  padding: .5rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: .6;
}

.title_producto {
  font-size: 1rem;
  color: #1E2022;
  font-weight: 600;
  text-transform: uppercase;
}

.medida_cart {
  font-size: 1rem;
  color: #e0324b;
}

.conten_cantidad_precio {
  width: 135px;
  padding: 1rem 0 0 0;
  display: grid;
  place-items: center;
  align-content: center;
  line-height: .6;
}

.cantidad_cart {
  font-size: .9rem;
  font-weight: 400;
}

.cantidad_cart > span {
  font-weight: 700;
  font-size: .9rem;
}

.precio_producto {
  font-size: 1rem;
  font-weight: 700;
}

.dolar {
  font-weight: 700;
  color: #e0324b;
}

.cada_uno_cart {
  font-weight: 600;
  font-size: .85rem;
}

.content_button_trash {
  display: grid;
  place-items: center;
  padding-right: .5rem;
}

.button_trash_cart {
  cursor: pointer;
  transition: all .3s ease;
}

.button_trash_cart:hover {
  color: #e0324b;
}

.content_buttons_actions {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 1rem .5rem 0 .5rem;
}

.button_ir_carrito {
  background-color: #e0324b;
  padding: 7px 15px;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: .9rem;
  transition: all .3s ease;
}

.button_ir_carrito:hover {
  background-color: #a82034;
}



@keyframes cart {
  0% {
    transform: translateY(-15%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes cartClosed {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-15%);
    opacity: 0;
  }
}

@media (max-width: 1280px) {
  .container_cart {
    position: absolute;
    top: 3rem;
    right: 5rem;
    background-color: #fff;
    padding: 1.5rem 1rem;
    transition: all .3s ease;
  }
}


@media (max-width: 920px) {
  .container_cart {
    position: absolute;
    top: 3rem;
    right: 5rem;
    background-color: #fff;
    padding: 1.5rem 1rem;
    transition: all .3s ease;
  }
}


@media (max-width: 725px) {
  .container_cart {
    width: 100vw;
    border: .5px solid #e2e2e2;
    box-shadow:  0 5px 8px -1px rgb(0 0 0 / 0.1), 0 0px 7px -2px rgb(0 0 0 / 0.1);
    position: absolute;
    top: 3.05rem;
    right: 0;
    left: 0;
    height: 100vh;
    background-color: #fff;
    padding: 1rem 1rem;
    z-index: 400;
    transition: all .3s ease;
  }

  .title_cart {
    font-size: 1rem;
    padding: 0 0 1rem .5rem ;
  }

  .card_cart {
    height: 550px;
    overflow: auto;
    scroll-behavior: smooth;
    overflow-x: hidden;
  }

  .title_producto {
    font-size: .8rem;
    text-transform: uppercase;
  }

  .medida_cart {
    font-size: .9rem;
    color: #e0324b;
  }

  .conten_cantidad_precio {
    width: 185px;
    padding: 1rem 0 0 0;
    display: grid;
    place-items: center;
    align-content: center;
    line-height: .6;
  }
  
  .cantidad_cart {
    font-size: .8rem;
    font-weight: 400;
  }
  
  .cantidad_cart > span {
    font-weight: 700;
    font-size: .8rem;
  }
  
  .precio_producto {
    font-size: .85rem;
    font-weight: 700;
  }

  .cada_uno_cart {
    font-weight: 600;
    font-size: .8rem;
  }
  
  .content_button_trash {
    display: grid;
    place-items: center;
  }
  
  .button_trash_cart {
    cursor: pointer;
    transition: all .3s ease;
  }
  
  .button_trash_cart:hover {
    color: #e0324b;
  }
  
  .content_buttons_actions {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 1rem .5rem 0 .5rem;
  }
  
  .button_ir_carrito {
    background-color: #e0324b;
    padding: 7px 15px;
    border-radius: 8px;
    border: none;
    color: #fff;
    font-weight: 600;
    font-size: .8rem;
    transition: all .3s ease;
  }
  
  .dolar {
    font-size: .9rem;
  }

  .content_img_cart {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .img_cart {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .content_info_producto_cart {
    display: flex;
    gap: .5rem;
    padding-bottom: 1rem;
    justify-content: center;
    align-items: center;
  }
}