
.contain_error_busqueda {
  width: 100%;
  height: 600px;

  display: grid;
  place-items: center;
  align-content: center;
}

.content_imagen_error {
  width: 300px;
}

.img_error_busqueda {
  width: 300px;
}

.content_info_error {
  display: grid;
  place-items: center;
}

.title_error {
  font-size: 2rem;
}

.p_error {
  font-size: 1.3rem;
  font-weight: 600;
}


