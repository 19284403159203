.title_modal {
  font-size: 1.6rem;
  color: var(--text-100);
  width: max-content;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_modal_vehiculo {
  width: 100vw;
  min-height: 100vh;
  z-index: 200;
  display: grid;
  place-items: center;
  padding-top: 20px;
}

.modal_vehiculo_tire {
  width: 1040px;
  height: 650px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding: 0 2.5rem 0 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  position: relative;
  overflow-x: hidden;
}


/*******************  INPUTS FILTROS  ********************/

.inputs_container {
  width: 1040px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0 5rem 0 0;
  justify-content: space-between;
}

.input_content {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: .6rem;
  height: 40px;
}

.label_input {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  height: 40px;
}

.input_vehiculo {
  height: 40px;
  width: 150px;
  border: 2.25px solid #888888; 
  border-radius: 6px;
  text-align: center;
  font-size: .9rem;
  transition: all .3s ease;
}

.input_vehiculo:hover {
  border: 2.25px solid var(--primary-100);
}

.input_seleccionado {
  height: 40px;
  width: 150px;
  border: 2.25px solid #e0324b;
  color: #e0324b;
  border-radius: 6px;
  text-align: center;
  font-size: .9rem;
  transition: all .2s ease;
  cursor: pointer;
}


/******************  BUTTONS RESULTS  ******************/

.container_buttons {
  margin: 3rem 0;
  width: 970px;
  height: 410px;
  overflow: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.content_button {
  width: 960px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(155px, 1fr));
  place-items: center;
  gap: 1.5rem 1.5rem;
}

.button_result_modal{
  background-color: var(--primary-100);
  width: 150px;
  padding: 10px 10px;
  border-radius: 6px;
  border: none;
  color: var(--primary-300);
  font-size: .9rem;
  transition: all .3s ease;
}

.button_result_modal:hover {
  background-color: #b62238;
}
/** END BUTTONS RESULTS **/


/******************  BUTTON VOLVER  **********************/

.container_button_volver {
  display: grid;
  place-items: center;
  width: max-content;
  position: absolute;
  bottom: 7px;
  height: 49px;
}

.button_volver {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .3rem;
  cursor: pointer;
}

.icon_volver {
  border: 1px solid #e0324b;
  border-radius: 100%;
  padding: 2px;
  display: grid;
  place-items: center;
  justify-content: center;
  transition: all .2s ease;
}

.icon {
  color: #e0324b;
}

.volver {
  color: #888888;
  font-size: 1rem;
  transition: all .2s ease;
}

.button_volver:hover .icon_volver {
  background-color: #e0324b;
  color: #fff;
}

.button_volver:hover .icon {
  color: #fff;
}

.button_volver:hover .volver {
  color: #e0324b;
}

/** END BUTTON VOLVER **/


/****************** VEHICULO RESULT **********************/

.container_vehicle {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(480px, 460px));
  height: 410px;
  align-items: start;
  padding-top: 1rem;
}

.container_image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img_content {
  width: 400px;
}

.image_vehicle {
  width: 400px;
}

.pie_foto {
  width: 430px;
}

.pie_info_foto {
  font-size: 10px;
}

.container_info {
  display: grid;
  gap: 2rem;
}


.title_medida_original {
  font-size: 1.3rem;
  color: var(--text-100);
  font-weight: 600;
}

.p_medida_original {
  font-weight: 600;
  color: #888888;
  font-size: 1rem;
}

.button_medida_original {
  width: 140px;
  height: 45px;
  background-color: var(--primary-100);
  border-radius: 8px;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all .3s ease;
}

.medida_original {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  padding: 11px;
}

.button_medida_original:hover {
  background-color: #b62238;
}

.title_medida_opcional {
  font-size: 1.3rem;
  color: var(--text-100);
  font-weight: 600;
}

.medida_opcional_space {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 100px));
  gap: 1rem;
  align-items: center;
}

.button_medida_opcional {
  display: flex;
  justify-content: center;
  align-items: start;
  border: 2px solid var(--primary-100);
  border-radius: 8px;
  height: 45px;
  font-size: 1rem;
  cursor: pointer;
  transition: all .3s ease;
}

.button_medida_opcional:hover {
  background-color: var(--primary-100);
}

.button_medida_opcional:hover .medida_opcional {
  color: #fff;
}

.medida_opcional {
  padding: 10px 12px;
  color: var(--text-100);
  font-weight: 600;
  font-size: .95rem;
}


.title_medida_rin {
  font-size: 1.3rem;
  color: var(--text-100);
  font-weight: 600;
}

.medida_rin_space {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.button_medida_rin {
  border: 2px solid var(--primary-100);
  border-radius: 8px;
  height: 45px;
  cursor: pointer;
  transition: all .3s ease;
}

.button_medida_rin_selected {
  border: 2px solid var(--primary-100);
  background-color: var(--primary-100);
  color: #fff;
  border-radius: 8px;
  height: 46px;
  cursor: pointer;
  transition: all .3s ease;
}

.medida_opcional_rin {
  padding: 10px 12px;
  color: var(--text-100);
  font-weight: 600;
  font-size: .95rem;
}

.medida_opcional_rin_selected {
  padding: 10px 12px;
  color: #fff;
  font-weight: 600;
}

.button_medida_rin:hover {
  background-color: var(--primary-100);
}

.button_medida_rin:hover .medida_opcional_rin {
  color: #fff;
}




@media (max-width: 1260px) {
  .title_modal {
    font-size: 1.3rem;
    height: max-content;
    padding: 1rem 0;
  }

  .modal_vehiculo_tire {
    height: 500px;
    width: 720px;
    padding: 0 1.5rem 0 1.5rem;
    gap: 1rem;
  }

  .inputs_container {
    width: 720px;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem 0;
    padding: 0 3rem 0 0;
  }

  .input_content {
    display: flex;
    justify-content: center;
    align-items: start;
    gap: .5rem;
    height: 30px;
  }
  
  .label_input {
    font-size: .9rem;
    height: 30px;
  }
  
  .input_vehiculo {
    height: 30px;
    width: 110px;
    border: 2.25px solid #888888; 
    font-size: .8rem;
  }

  .input_seleccionado {
    height: 30px;
    width: 110px;
    font-size: .8rem;
  }

  .container_buttons {
    margin: 2rem 0;
    width: 670px;
    height: 310px;
    overflow: auto;
    scroll-behavior: smooth;
    overflow-x: hidden;
  }
  
  .content_button {
    display: grid;
    width: 660px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    place-items: center;
    gap: 1rem .5rem;
  }
  
  .button_result_modal{
    width: 140px;
    padding: 10px 10px;
    font-size: .7rem;
    font-weight: 600;
  }

  .container_vehicle {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 330px));
    height: 350px;
    place-items: start;
  }
  
  .container_image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 330px;
    padding-bottom: .5rem;
  }
  
  .img_content {
    width: 330px;
  }
  
  .image_vehicle {
    width: 280px;
  }
  
  .pie_foto {
    width: 330px;
  }
  
  .container_info {
    display: grid;
    width: 330px;
    gap: 1rem;
  }
  
  
  .title_medida_original {
    font-size: 1rem;
  }
  
  .p_medida_original {
    font-size: .8rem;
  }
  
  .button_medida_original {
    width: 90px;
    height: 35px;
  }
  
  .medida_original {
    padding-top: 9px;
    font-size: .8rem;
  }
  
  .title_medida_opcional {
    font-size: 1rem;
  }
  
  .medida_opcional_space {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px,90px));
    gap: .5rem;
    align-items: center;
    font-size: .8rem;
  }
  
  .button_medida_opcional {
    height: 35px;
    width: 90px;
    font-size: .8rem;
  }
  
  .medida_opcional {
    padding: 7px;
    font-size: .8rem;
  }
  
  
  .title_medida_rin {
    font-size: 1rem;
    width: 300px;
  }
  
  .medida_rin_space {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  .button_medida_rin {
    border: 2px solid var(--primary-100);
    border-radius: 8px;
    height: 35px;
    cursor: pointer;
    transition: all .3s ease;
  }
  
  .button_medida_rin_selected {
    border: 2px solid var(--primary-100);
    background-color: var(--primary-100);
    color: #fff;
    border-radius: 8px;
    height: 35px;
    cursor: pointer;
    transition: all .3s ease;
  }
  
  .medida_opcional_rin {
    padding: 5px;
    color: var(--text-100);
    font-weight: 600;
    font-size: .8rem;
  }
  
  .medida_opcional_rin_selected {
    padding: 5px;
    color: #fff;
    font-weight: 600;
    font-size: .8rem;
  }

  
}

@media (max-width: 730px) {
  .title_modal {
    font-size: 1.3rem;
    height: max-content;
    padding: 1rem 0;
  }

  .modal_vehiculo_tire {
    height: 425px;
    width: 335px;
    padding: 0 .5rem 0 .5rem;
    gap: 1rem;
  }

  .modal_content_ {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }

  .inputs_container {
    width: 320px;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem 0;
    padding: 0 .5rem;
  }

  .input_content {
    display: flex;
    justify-content: center;
    align-items: start;
    gap: .3rem;
    height: 30px;
  }
  
  .label_input {
    font-size: .8rem;
    height: 30px;
  }
  
  .input_vehiculo {
    height: 30px;
    width: 100px;
    border: 2.25px solid #888888; 
    font-size: .7rem;
  }

  .input_seleccionado {
    height: 30px;
    width: 100px;
    font-size: .7rem;
  }

  .container_buttons {
    margin: 1.5rem 0;
    width: 320px;
    height: 240px;
    overflow: auto;
    scroll-behavior: smooth;
    overflow-x: hidden;
  }
  
  .content_button {
    display: grid;
    width: 300px;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    place-items: center;
    gap: 1rem .5rem;
  }
  
  .button_result_modal{
    width: 120px;
    padding: 10px 10px;
    font-size: .7rem;
    font-weight: 600;
  }

  .container_button_volver {
    display: none;
  }

  .container_vehicle {
    display: grid;
    grid-template-columns:none;
    width: 330px;
    height: 410px;
    place-items: start;
  }
  
  .container_image {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 330px;
    padding-bottom: .5rem;
  }
  
  .img_content {
    width: 200px;
  }
  
  .image_vehicle {
    width: 200px;
  }
  
  .pie_foto {
    display: none;
  }
  
  .container_info {
    display: grid;
    place-items: center;
    width: 300px;
    gap: 1rem;
  }
  
  
  .title_medida_original {
    font-size: 1rem;
  }
  
  .p_medida_original {
    font-size: .8rem;
    text-align: center;
  }
  
  .button_medida_original {
    width: 90px;
    height: 35px;
    margin: 0 auto;
  }
  
  .medida_original {
    padding-top: 9px;
    font-size: .8rem;
  }
  
  .title_medida_opcional {
    font-size: 1rem;
    text-align: center;
  }
  
  .medida_opcional_space {
    width: 200px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px,90px));
    gap: .5rem;
    place-content: center;
    align-items: center;
    font-size: .8rem;
  }
  
  .button_medida_opcional {
    height: 35px;
    width: 90px;
    font-size: .8rem;
  }
  
  .medida_opcional {
    padding: 7px;
    color: var(--text-100);
    font-weight: 600;
    font-size: .8rem;
  }
  
  
  .title_medida_rin {
    font-size: 1rem;
    color: var(--text-100);
    font-weight: 600;
    width: 250px;
  }
  
  .medida_rin_space {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  
  .button_medida_rin {
    border: 2px solid var(--primary-100);
    border-radius: 8px;
    height: 35px;
    cursor: pointer;
    transition: all .3s ease;
  }
  
  .button_medida_rin_selected {
    border: 2px solid var(--primary-100);
    background-color: var(--primary-100);
    color: #fff;
    border-radius: 8px;
    height: 35px;
    cursor: pointer;
    transition: all .3s ease;
  }
  
  .medida_opcional_rin {
    padding: 5px;
    color: var(--text-100);
    font-weight: 600;
    font-size: .8rem;
  }
  
  .medida_opcional_rin_selected {
    padding: 5px;
    color: #fff;
    font-weight: 600;
    font-size: .8rem;
  }
  
}