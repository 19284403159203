:root {
  --sombra-cajas-dashboard : 0 4px 10px -1px rgb(0 0 0 / 0.1), 0 2px 7px -2px rgb(0 0 0 / 0.1);
}

.management-containerD {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 480px;
  overflow-y: auto;
  padding: 0; /* Elimina el padding extra para que el scroll esté más pegado */
}

/* Estilo del scroll más delicado */
.management-containerD::-webkit-scrollbar {
  width: 6px; /* Barra más delgada */
}

.management-containerD::-webkit-scrollbar-thumb {
  background-color: rgba(85, 85, 85, 0.3); /* Color más suave */
  border-radius: 10px;
}

.management-containerD::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.5); /* Color al hacer hover */
}

.management-containerD::-webkit-scrollbar-track {
  background: transparent; /* Fondo transparente */
}

/* Estilos de las tarjetas de conversación */
.card-conversacionD {
  border-radius: 3px;
  margin: 5px 0; /* Ajusta el margen entre las tarjetas */
  padding: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  position: relative;
  background-color: #f9f9f9;
}

.card-conversacionD:hover {
  background-color: #ff90ff18;
}

/* Contenido dentro de cada tarjeta */
.chat-itemD {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #e7e6e6;
  padding-top: 20px;
  margin-left: 10px;
  position: relative;
}

.chat-itemD__info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chat-itemD__date {
  font-size: 11px;
  font-weight: bold;
  color: #888;
  position: absolute;
  top: 0px; /* Reduce el margen superior */
  right: 8px;
  margin: 0; /* Elimina márgenes adicionales */
}

.chat-itemD__name {
  font-weight: bold;
  font-size: 13px;
  margin: 5px 0; /* Un pequeño margen entre la fecha y el nombre */
}

.chat-itemD__description {
  font-size: 11px;
  color: #7a7a7a;
  margin-bottom: 5px;
}

.chat-itemD__button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 0px;
}

.chat-itemD__img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

  /* Card de los widgets */

  .contacts {
    background-color: #007bff31;
    height: 140px;
    border-radius: 5px;
    transition: background-color .3s ease;
    box-shadow: var(--sombra-cajas-dashboard);
  }

  .contacts:hover {
    background-color: #007bff4f;
  }

  .gestiones {
    background-color: #8884d836;
    height: 140px;
    border-radius: 5px;
    transition: background-color .3s ease;
    box-shadow: var(--sombra-cajas-dashboard);
  }

  .gestiones:hover {
    background-color: #8884d85e;
  }

  .compaing {
    background-color: #dd55002f;
    height: 140px;
    border-radius: 5px;
    transition: background-color .3s ease;
    box-shadow: var(--sombra-cajas-dashboard);
  }

  .compaing:hover {
    background-color: #dd550048;
  }


  /* Numero de contatos, users, y compaing dentro del card de los widgets */
  .color_gestiones {
    color: #8884d8;
  }

  .color_compaing {
    color: #dd5600;
  }



  /* Caja contenedora de los iconos de la card de widgets */

  .content_icon{
    position: absolute;
    top: -15px;
    right: 15px;
    padding: 13px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.2), 0 2px 4px -2px rgb(0 0 0 / 0.2);  
  }

  .icon_contact {
    background-color: #007bff;
  }

  .icon_gestiones {
    background-color: #8884d8;
  }

  .icon_compaing {
    background-color: #dd5600;
  }



/*  Tabla des dashboard  */

.tabla {
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}

/* Estilos para la barra de desplazamiento en navegadores WebKit (Safari y Chrome) */
.tabla::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}

.tabla::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2); /* Color de la barra de desplazamiento */
  border-radius: 10px; /* Borde redondeado de la barra de desplazamiento */
}

.tabla::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.4); /* Cambia el color de la barra de desplazamiento al hacer hover */
}

.tabla::-webkit-scrollbar-track {
  background: transparent; /* Fondo transparente */
}

.headerTable {
  background-color: #ffc0cb56;
  padding: 10px;
}

.animated:hover {
  background-color: #dfe1e6;
  transition: background-color .3s ease;
}
.custom-scroll::-webkit-scrollbar {
  width: 8px; /* Ancho del scrollbar */
}

.custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color del fondo del scrollbar */
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #888; /* Color de la barra */
  border-radius: 10px; /* Bordes redondeados */
  border: 2px solid #f1f1f1; /* Espacio entre la barra y el fondo */
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color de la barra cuando se pasa el mouse */
}
