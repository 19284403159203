.main_container {
  padding: 5rem 3rem;
}

.content_rendimiento {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem 2rem 0;
  position: relative;
}

.content_graficas_ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.content_rendimiento_analisis {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 325px);
  gap: 1rem;
}

.title_productos {
  font-size: 1.7rem;
  color: #1E2022;
  font-weight: 600;
}

.card_redimiento {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 2rem 2rem 1rem 2rem;
  box-shadow: 0 5px 8px 0 rgb(0 0 0 / 0.1);
}

.content_stats_rendimiento {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
}

.rendi_prom {
  font-size: 1.6rem;
  font-weight: 600;
}

.rendi_porcen {
  background-color: #e0324c25;
  padding: 5px;
  height: 100%;
  border-radius: 6px;
}

.button_filter_analisis {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  gap: 4rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  transition: all .2s ease;
}

.button_filter_analisis:hover {
  border: 1px solid #e0324b;
}

.button_filter_analisis:hover .icon_fecha_rango {
  color: #e0324b;
}

.button_filter_analisis_active {
  border: 1px solid #e0324b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  gap: 4rem;
  border-radius: 6px;
  background-color: #fff;
  transition: all .2s ease;
}

.button_filter_analisis_active .icon_fecha_rango {
  color: #e0324b;
}

.negrita {
  font-weight: 600;
}

.content_graficos_analisis {
  display: grid;
  grid-template-columns: repeat(auto-fit, 48%);
  gap: 1.5rem;
  padding-top: 2rem;
}

.title_graficos_ {
  padding-left: 1rem;
  font-size: 1.1rem;
}

.content_grafica_analisis {
  border: 1px solid #ddd;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 5px 8px 0 rgb(0 0 0 / 0.1);
}

.content_favorito_analisis {
  border: 1px solid #ddd;
  padding: 1.5rem;
  border-radius: 8px;
  display: grid;
  grid-template-rows: auto 350px;
  box-shadow: 0 5px 8px 0 rgb(0 0 0 / 0.1);
}

.content_info_fav {
  display: grid;
  place-items: center;
}

.content_button_calendary {
  position: relative;
}

.content_dots_vertical {
  cursor: pointer;
  transition: all .2s ease;
}

.content_dots_vertical:hover {
  color: #e0324b;
}


/*************** Visualizador ***************/

@keyframes open_visualizador {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.content_visualizador {
  display: none;
}

.content_visualizador_open {
  position: absolute;
  background-color: #fff;
  padding: 2rem 1rem 2rem 2rem;
  top: 2rem;
  right: 2rem;
  box-shadow: 0 5px 8px 0 rgb(0 0 0 / 0.1);
  border-radius: 8px;
  border: 1px solid #ddd;
  animation: open_visualizador .3s ease;
  z-index: 10;
}

.visualizador_container {
  max-height: 500px;
  overflow: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.content_name_visualizador {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding-right: 1rem;
  cursor: pointer;
  transition: all .2s ease;
  font-size: 1rem;
}

.content_name_visualizador:hover {
  color: #e0324b;
}

/**************************/


/************** Skeleton Loader **************/

.skeleton {
  background-color: #f0f0f0;
  border-radius: 4px;
  margin: 8px 0;
  position: relative;
  overflow: hidden;
}

.skeleton_title {
  height: 24px;
  width: 80%;
  margin-bottom: 3px;
  background-color: #e0e0e0;
  animation: loading 1.5s infinite linear;
}

.skeleton_stat {
  height: 24px;
  width: 15%;
  margin-bottom: 8px;
  background-color: #e0e0e0;
  animation: loading 1.5s infinite linear;
}

@keyframes loading {
  0% {
    background-position: 200%;
  }
  100% {
    background-position: -200%;
  }
}

.skeleton {
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite linear;
}



/*************** Calendario ***************/

