.contain_navbar_market {
  width: 100%;
}

.content_navbar_market {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.layout_ {
  display: grid;
  gap: 2rem;
}

.content_searh_market {
  display: flex;
}

.input_search_market {
  width: 400px;
  border: 1px solid #ddd;
  border-radius: 6px 0 0 6px;
  padding: 5px 11px;
  transition: all .2s ease;
  color: #1E2022;
}

.input_search_market:hover {
  border: 1px solid #e0324b;
}

.input_search_market:focus {
  outline: none;
  border: 1px solid #e0324b;
}

.content_button_search_market {
  background-color: #e0324b;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0 6px 6px 0;
  transition: all .2s ease;
  cursor: pointer;
}

.content_button_search_market:hover {
  background-color: #a01e31;
}

.contain_list_type {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: .5rem;
  height: 40px;
  width: 40px;
  border: 1px solid #888;
  border-radius: 6px;
  transition: all .2s ease;
}

.contain_list_type:hover {
  border: 1px solid #e0324b;
}

.contain_list_type:hover .content_list_type {
  color: #e0324b;
}

.content_list_type {

}
