.content_politicas_privacidad {
  padding: 7rem 2rem;
}

.title_politicas_privacidad {
  font-weight: 400;
  text-transform: uppercase;
}

p, li {
  font-size: 1.1rem;
}
.span_p_p {
  font-size: 1.1rem;
  font-weight: 700;
}
.content_info_priv {
  padding: 1rem 0 0 0;
}

.content_indice {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content_img_politi {
  display: none;
}

.img_politi {
  display: none;
}

@media (min-width: 720px) {
  .content_politicas_privacidad {
    padding: 7rem 3rem;
  }
}

@media (min-width: 1000px) {
  .content_politicas_privacidad {
    padding: 7rem 4rem;
  }
  .content_info_priv {
    display: grid;
    gap: .3rem;
    padding: 1.5rem 2rem 0 2rem;
  }
  .content_img_politi {
    width: 400px;
    display: flex;
    animation: animation_llanta .5s ease;
  }
  
  .img_politi {
    display: flex;
    width: 400px;
  }
  
}

@media (min-width: 1200px) {
  .content_politicas_privacidad {
    padding: 8rem 10rem;
  }
  .content_img_politi {
    display: flex;
    width: 600px;
    animation: animation_llanta .5s ease;

  }
  
  .img_politi {
    width: 600px;
  }
  
}

@keyframes animation_llanta {
  0% {
    transform: translateX(150px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}