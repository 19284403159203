.container_form_pagar {
  padding: 7rem 2rem 2rem 2rem;
  background-color: #eaeded;
  display: grid;
  gap: 1.5rem;
}

.container_form_pasos {
  display: grid;
  gap: 1rem;
}

.select_pagar {
  display: none;
}

.show_salect_pagar {
  position: absolute;
  max-height: 280px;
  top: 3.1rem;
  width: 100%;
  display: grid;
  background-color: #fff;
  padding: .5rem;
  border-radius: 6px;
  border: 1px solid #ebeaf2;
  box-shadow: 0 7px 10px 0 #d7d5e2;
  z-index: 100;
  overflow: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.icon_chevron {
  transform: rotate(0deg);
  transition: transform .2s ease;
}

.rotate {
  transform: rotate(90deg);
  transition: transform .2s ease;
}

.button_select_pagar {
  background-color: transparent;
  padding: 7px 20px;
  text-align: left;
  border-radius: 6px;
  transition: all .1s ease;
  border: none;
  cursor: pointer;
}

.button_select_pagar:hover {
  background-color: #e0324b;
  color: #fff;
}

.form_pagar {
  background-color: #fff;
  border-radius: 6px;
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  display: grid;
  gap: 1rem;
  box-shadow: 0 5px 8px 0 rgb(0 0 0 / 0.1);
}

.content_label_input {
  display: grid;
  line-height: .8;
  position: relative;
}

.label_pagar {
  text-transform: uppercase;
  letter-spacing: .01px;
  font-size: .9rem;
}

.input_pagar {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 8px 10px;
  font-size: .8rem;
  transition: all .2s ease;
  width: 100%;
}

.input_pagar:hover {
  border: 1px solid #e0324b;
}

.input_pagar:focus {
  outline: none;
  border: 1px solid #e0324b;
}

.container_productos_pagar__ {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 5px 8px 0 rgb(0 0 0 / 0.1);
  padding: 1rem .5rem 1rem 0;
  
}

.content_title_productos_pagar {
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
}

.content_producto_pagar {
  display: flex;
  padding: .5rem 0;
}

.content_img_pagar {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_pagar {
  width: 80px;
}

.content_info_product_pagar {
  display: grid;
  padding: 10px 0;
}

.name_llanta_pagar {
  font-size: .8rem;
  font-weight: 800;
}

.cantidad_llanta_pagar {
  font-size: .8rem;
}

.precio_pagar {
  font-size: .8rem;
}

.container_precios_pagar {
  padding: 1rem 1rem 0 1rem;
}

.content_precio_pagar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.precio_pagar_total {
  font-size: 1.2rem;
  font-weight: 600;
}

.title_precio_pagar {
  font-size: 1.2rem;
}

.info_entrega {
  padding: 1rem 0 0 0;
}

.tiempo_entrega {
  color: #9a9a9a;
  font-size: .9rem;
  text-align: center;
}

.container_button_detalle {
  position: fixed;
  background-color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.14);
}

.content_toal_precio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  
}

.title_precio_pagar_ {
  font-size: 1.35rem;
  font-weight: 600;
}

.precio_pagar_ {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: -0.05em;
}

.button_siguiente_pagar {
  border: none;
  background-color: #e0324b;
  color: #fff;
  width: 100%;
  font-size: 1.4rem;
  padding: 20px 0;
  cursor: pointer;
  transition: all .2s ease;
}

.disabled_button_siguiente{
  cursor: not-allowed;
  background-color: #a82034;
}

.button_siguiente_pagar:hover {
  background-color: #a82034;
}

.content_pasos {
  display: flex;
  justify-content: space-around;
  align-items: start;
}

.pasos {
  display: grid;
  place-items: center;
  width: 50px;
  align-items: center;
  color: #9a9a9a;
}

.icon_pasos {
  font-size: 1.8rem;
}

.active_pasos {
  color: #a82034;
}

.active_pasos_next {
  color: #e0324b;
  cursor: pointer;
}

.p_pasos {
  text-align: center;
  line-height: .8;
  font-size: 1rem;
}


.container_info_envios {
  display: grid;
}

.content_envio_card {
  border-radius: 6px;
  padding: 1rem;
  border: 3px solid #e0324b;
  transition: all .2s ease;
  height: 135px;
  padding-top: 25px;
}

.envio_card_cursor {
  cursor: pointer;
}

.envio_card_cursor:hover {
  border: 3px solid #a82034;
  background-color: #e0324c25;
}

.p_envio_llanta_pagar {
  font-size: 1.2rem;
  font-weight: 600;
}

.p_envio_llanta_pagar_ {
  font-size: 1.2rem;
  font-weight: 600;
  color: #e0324b;
}

.cambiar_envio_destino {
  color: #e0324b;
  border: 1px solid #e0324b;
  border-radius: 6px;
  padding: 4px 8px;
  transition: all .2s ease;
}

.cambiar_envio_destino:hover {
  background-color: #e0324b;
  color: #fff;
}

.content_button_pagar_products {
  display: none;
}

.content_img_pagar_form {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 600px) {
  .form_pagar {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

}

@media (min-width: 650px) {
  .container_productos_a_pagar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    
  }
  
  .name_llanta_pagar {
    font-size: .9rem;
  }
  
  .cantidad_llanta_pagar {
    font-size: .9rem;
  }
  
  .precio_pagar {
    font-size: .9rem;
  }
  .icon_pasos {
    font-size: 1.9rem;
  }
  
  .content_pasos {
    width: 600px;
  }
  
  .form_pagar {
    width: 600px;
    padding-bottom: 5rem;
    position: relative;
  }
  
  .container_form_pasos {
    display: grid;
    place-items: center;
  }
  
  .container_productos_pagar {
    display: grid;
    place-items: center;
  }
  
  .container_productos_pagar__ {
    width: 600px;
  }

  .container_button_detalle {
    display: none;
  }

  .content_button_pagar_products {
    display: flex;
    position: absolute;
    right: 1.5rem;
    bottom: 1rem;
  }

  ._button_pagar_siguiente {
    background-color: #e0324b;
    border: none;
    padding: 8px 12px;
    color: #fff;
    border-radius: 6px;
  }

  .disabled_button_siguiente_pagar{
    cursor: not-allowed;
    background-color: #a82034;
  }
}

@media (min-width: 1010px) {
  
  .form_pagar {
    width: 850px;
  }

  
}

@media (min-width: 1250px) {
  .container_productos_pagar__ {
    width: 310px;
  }

  .container_productos_a_pagar {
    grid-template-columns: 1fr;
  }

  .container_form_pagar {
    grid-template-columns: auto auto;
    gap: 1.5rem;
    place-content: center;
  }

  .icon_pasos {
    font-size: 2rem;
  }
}