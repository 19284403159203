
.content_estadisticas {
  display: grid;
  gap: 2.5rem;
}

/************ NavbarEstadisticas *************/

.container_nav_estadisticas {
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 40px;
  position: relative;
}

.buttons_navigation {
  position: relative;
  display: flex;
  gap: 1rem;
}

.button_navigation_estadistica {
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all .2s ease;
  padding: .5rem .5rem;
  position: relative;
}

.border_button_navigation_estadistica {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #e0324b;
  transition: left .3s ease, width .3s ease;
  width: 0;
}

.button_navigation_estadistica:hover {
  color: #e0324b;
}

.button_navigation_estadistica_active {
  color: #e0324b;
}

/***********  Estilos Resaltados  ************/

.content_estadisticas_ {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
}

.content_estadisticas_totales {
  display: grid;
  gap: 1rem;
  border-radius: 6px;
  border: 1px solid #ddd;
  padding: 2rem;
  box-shadow: 0 5px 8px 0 rgb(0 0 0 / 0.1);
}

.content_stats_email_open {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.title_name_producto_estadistica {
  font-weight: 400;
  padding-bottom: 1rem;
  font-size: 2.2rem;
  text-transform: uppercase;
}

.content_vistas_estadisticas_totales {
  display: flex;
  gap: 4rem;
  line-height: 1.3;
}

.title_estadisticas_resaltados {
  font-size: 1.25rem;
}

.title_stats {
  font-size: 1.1rem;
  font-weight: 600;
}

.valor_vista_stats {
  font-size: 2.3rem;
  font-weight: 600;
}

.number_megusta {
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  width: 40px;
  background-color: #e0324c25;
  font-weight: 600;
  font-size: 1.3rem;
  height: max-content;
}

.content_buttons_range {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button_range_date {
  background-color: #fdfdfb;
  color: #1E2022;
  border: 1px solid #ddd;
  padding: 10px 10px;
  width: 120px;
  font-weight: 600;
  transition: all .2s ease;
}

.left_rage {
  border-radius: 6px 0 0 6px;
}

.right_rage {
  border-radius: 0 6px 6px 0;
}

.button_range_date:hover {
  border: 1px solid #e0324b;
}

.button_range_active {
  background-color: #e0324b;
  color: #fff;
  border: 1px solid #e0324b;
  padding: 10px 10px;
  width: 120px;
  font-weight: 600;
}

.p_email_open {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1;
}

.position_stats {
  display: grid;
  justify-content: start;
}



.content_cart_ {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 2rem 2rem 1rem 2rem;
  display: grid;
  grid-template-rows: 1fr auto;
  width: 300px;
}

.content_but_ {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  border-top: 1px solid #ddd;
  padding-top: 1rem;
}

.content_but_ > button {
  border: none;
  padding: 7px 10px;
  border-radius: 6px;
  background-color: #2c9bdd;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}