
.content_loading_home {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  top: 0;
  width: 100%;
  animation: slideUp 3s forwards;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.loading_mask {
  width: 100%;
  height: 100%;
  background-color: #fff;
  animation: loading_home 2s ease;
  position: absolute;
  box-shadow: -1px 0 0 0 rgba(255, 255, 255, 0),
              -2px 0 0 2px rgba(255, 255, 255, 0.5),
              -3px 0 0 3px rgba(255, 255, 255, 0.2);
}


.img_loading {
  width: 180px;
  display: block;
  height: auto;
}

@keyframes loading_home {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(110%);
  }
  100% {
    transform: translateX(110%);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

