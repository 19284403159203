.page_llantas {
  display: grid;
  place-items: center;
}

.container_page_llantas {
  width: 1350px;
  display: grid;
  grid-template-columns: auto 1fr;
  padding-top: 6.5rem;
  gap: 2rem;
  padding-bottom: 2rem;
}


/****************  FILTROS  *******************/

.content_filtro {
  width: 260px;
  height: 89vh;
  display: grid;
  gap: 1rem;
  padding: 0 10px;
  border-right: 1px solid #e0324c3d;
  padding-bottom: 2rem;
  
  overflow: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.filtros {
  display: grid;
  width: 250px;
  gap: 1rem;
  align-content: start;
}

.contenido_filtro {
  display: grid;
  gap: .3rem;
  align-items: center;
}

.content_title {
  width: 230px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.opcion_filtro {
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease;
}

.filtros_opciones {
  max-height: 0;
  overflow: hidden;
  transition: max-height .4s ease-in-out;
}

.active_options {
  max-height: 300px;
  transition: max-height .4s ease-in-out;
}

.icon_chevron {
  transform: rotate(0deg);
  transition: transform .3s ease;
}

.rotate {
  transform: rotate(90deg);
  transition: transform .4s ease;
}


.title_filtrar_por {
  font-size: 1.3rem;
  font-weight: 600;
  color: #1E2022;
}

.title_filtros {
  font-size: 1.1rem;
  color: #1E2022;
  transition: all .2s ease;
}

.content_title:hover .title_filtros {
  color: #e0324b;
}

.content_title:hover .icon_chevron {
  color: #e0324b;
}

.label_filtro {
  font-size: 1.05rem;
  font-weight: 400;
}

.input_filtros {
  width: 15px;
  height: 15px;
}

/*** ESTILOS FILTRO PRECIO ***/

.input_precio {
  width: 103px;
  height: 35px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background-color: #d9d9d9;
  text-align: center;
}

.content_input_precio {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: .5rem;
}
.guion {
  font-weight: 600;
  font-size: 1.5rem;
}

.input_range {
  -webkit-appearance: none;
  appearance: none;
  background-color: #e0324c3d;
  color: #e0324b;
  height: 13px;
  width: 100%;
  border-radius: 20px;
}

.input_range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #e0324b;
  border: 5px solid #fff;
  cursor: pointer;
  
  background-size: 45%;
  background-position: center;
  background-repeat: no-repeat;
}

.filtros_precios {
  width: 230px;
  display: grid;
  gap: .5rem;
  transition: max-height 1s ease;
  max-height: 300px;
}

.active_options_precio {
  max-height: 0;
  overflow: hidden;
  transition: max-height .4s ease;
}


/************  CART LLANTA  *************/


.container_llantas {
  display: grid;
  grid-template-rows: auto 1fr;
}

.container_llantas {
  display: grid;
  gap: 1.2rem;
}

.llantas_list {
  display: grid;
  gap: 1.2rem;
  place-items: start;
}

.llantas_list_layout {
  display: grid;
  width: 100%;
  gap: 2rem 2rem;
  grid-template-columns: repeat(auto-fit, 300px);
}

.title_page_llantas{
  color: #1E2022;
  font-weight: 600;
}

.cart_llanta {
  width: 980px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px 20px 0 15px;
  transition: all .3s ease;
  color: #495057;
  box-shadow:  0 7px 10px 0 rgb(0 0 0 / 0.1);
  /* animation: moveRight .6s forwards;
  opacity: 0;
  transition: opacity 0.5s; */
}

.cart_llanta_layout {
  width: 300px;
  display: grid;
  place-items: center;
  color: #495057;
  border: 1px solid #ddd;
  box-shadow:  0 7px 10px 0 rgb(0 0 0 / 0.1);
  transition: all .3s ease;
  border-radius: 10px;
}

.content_imagenes {
  display: grid;
  grid-template-rows: auto auto;
  gap: .5rem;
  place-items: center;
  padding-top: 1rem;
  border: 1px solid #fff;
  border-radius: 6px;
  height: 300px;
  width: 225px;
  transition: all .3s ease;
}

.content_imagenes_layout {
  display: grid;
  place-items: center;
  border-bottom: 1px solid #ddd;
  width: 300px;
  height: 250px;
}

.content_imagenes:hover {
  border: 1px solid #e0324b;
}

.content_image_llanta{
  width: 200px;
}

.content_image_llanta_layout {
  width:300px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.image_llanta {
  width: 200px;
  height: 210px;
  cursor: pointer;
  transition: all .3s ease;
  object-position: center;
  background-position: center;
  object-fit: contain;
}

.image_llanta:hover {
  transform: scale(1.08);
  z-index: 1000;
}
.content_image_llanta_layout:hover .image_llanta {
  transform: scale(1.1);
  z-index: 1000;
}
.content_image_marca {
  width: 200px;
}

.image_marca {
  width: 200px;
  height: 40px;
  object-position: center;
  background-position: center;
  object-fit: cover;
}

.image_marca_layout {
  display: none;
}

.info_llantas {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding-bottom: 1rem;
}

.info_llantas_layout {
  display: grid;
  gap: 1rem;
}

.content_name_card {
  padding: 1rem 0;
  line-height: .6;
}

.content_name_card_layout {
  padding: 0 1rem;
  line-height: .3;
}


.name_llanta_card {
  font-size: 1.6rem;
  color: #1E2022;
  transition: all .2s ease;
  text-decoration: none;
  font-weight: 600;
}

.name_llanta_card_layout {
  font-size: 1.2rem;
  color: #1E2022;
  transition: all .2s ease;
  text-decoration: none;
  font-weight: 600;
  line-height: 1;
}

.name_llanta_card:hover {
  color: #e0324b;
  text-decoration: underline;
}

.name_llanta_card_layout:hover {
  color: #e0324b;
  text-decoration: underline;
}

.medida_llanta {
  font-size: 1.2rem;
  color: #e0324b;
}

.medida_llanta_layout {
  font-size: 1.05rem;
  color: #e0324b;
}

.content_caracteristicas {
  line-height: .3;
}

.span_llanta {
  font-weight: 600;
  text-transform: uppercase;
  font-size: .9rem;
}

.caracteristicas_llanta {
  font-size: .9rem;
}

.content_precios {
  display: grid;
  place-items: end;
  align-content: center;
  line-height: 2;
}

.content_precios_layout {
  width: 100%;
  padding: 0 1rem 1rem 1rem;
  display: grid;
  grid-template-columns: auto 1fr;
  align-content: start;
  line-height: 1;
  position: relative;
}

.precios {
  line-height: .6;
  display: grid;
  place-items: end;
}

.precios_layout {
  line-height: .3;
  display: grid;
  place-items: start;
  height: max-content;
}

.precio_viejo {
  padding-top: 1.5rem;
  font-size: 1.3rem;
  text-decoration: line-through;
}

.precio_viejo_layout {
  padding-top: 1rem;
  font-size: .85rem;
  text-decoration: line-through;
}

.precio_nuevo {
  font-size: 1.6rem;
  color: #e0324b;
  font-weight: 600;
}

.precio_nuevo_layout {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1E2022;
}

.envios {
  font-weight: 600;
  font-size: .8rem;
  color: #09cc09;
}

.envios_layout {
  position: absolute;
  font-weight: 600;
  color: #09cc09;
  font-size: .75rem;
  left: 1rem;
  bottom: 3px;
}



/******************  Page LLanta Para Comprar  ******************/

.page_llanta_market{
  display: grid;
  height: 100vh;
  place-items: center;
  padding: 10rem 0 5rem 0;
  gap: 5rem;
}

.container_llanta {
  padding: 1rem;
  width: 1280px;
  display: grid;
  /* gap: 2rem; */
  box-shadow:  0 5px 8px -1px rgb(0 0 0 / 0.1), 0 0px 7px -2px rgb(0 0 0 / 0.1);
  border-radius: 10px;
  color: #5c5e60;
}

.content_llanta {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.content_name {
  padding: 2rem 0;
}

.name_llanta {
  font-size: 2.3rem;
  color: #1E2022;
}

.name_medida {
  color: #e0324b;
}

.content_imagen_miniatura {
  width: 90px;
  height: 110px;
  border: .5px solid #b6b6b6;
  border-radius: 8px;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all .3s ease;
}
.content_imagen_miniatura:hover {
  border: 1px solid #e0324b;
}

.content_imagen_miniatura:hover .imagen_miniatura{
  transform: scale(1.1);
}

.imagen_miniatura {
  width: 75px;
  transition: all .3s ease;
}

.info_llanta {
  width: 100%;
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding: 0 10px;
}

.content_imagenes_detalles_llanta {
  display: flex;
  gap: 1rem;
}

.container_img_modelo {
  display: grid;
  gap: .5rem;
  place-items: center;
  cursor: pointer;
}

.content_img_medida {
  width: 350px;
  display: grid;
  place-items: center;  
}

.img_medida {
  width: 330px;
  height: 330px;
  cursor: pointer;
  transition: all .3s ease;
  object-position: center;
  background-position: center;
  object-fit: cover;
}

.content_img_marca {
  width: 350px;
  display: grid;
  place-items: center;
}

.img_marca {
  width: 320px;
  height: 70px;
  object-position: center;
  background-position: center;
  object-fit: cover;
}

.content_precios_detalles{
  display: grid;
  place-items: end;
  align-content: center;
  line-height: 2;
  padding: 0 .5rem 0 1rem;
  width: 70%;
}

.precios {
  position: relative;
  padding-bottom: .7rem;
}

.info_llantas_caracteristicas {
  line-height: .7;
  font-size: 1rem;
  color: #808080;
  cursor: pointer;
  transition: all .2s ease;
}

.info_llantas_caracteristicas:hover {
  color: #e0324b;
}

.precio_viejo_market {
  font-size: 1.7rem;
  color: #686868;
}
.line {
  width: 110px;
  height: 2px;
  background-color: #686868;
  position: absolute;
  top: 8px;
  right: 30px;
}
.precio_nuevo_market {
  font-size: 2rem;
  font-weight: 700;
}

.cada_uno {
  font-size: 1.2rem;
  text-decoration: none;
}

.content_envios {
  line-height: .4;
  display: grid;
  place-items: end;
}

.envios_market {
  color: #09cc09;
  font-weight: 600;
  font-size: 1.2rem;
}

.iva_market {
  font-size: .8rem;
}

.content_buttons_market {
  display: grid;
  place-items: end;
  gap: .8rem;
}

.content_buttons_market_layout {
  display: grid;
  place-items: end;
  gap: .5rem;
  padding-top: 1.5rem;
}

.cantidad {
  text-align: end;
  font-size: .9rem;
  font-weight: 700;
  color: #5c5e60;

}

.content_buttons_cantidad {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons_cantidad {
  width: 35px;
  height: 35px;
  background-color: #e0324b;
  color: #fff;
  border: none;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 6px;
  transition: all .3s ease;
}

.buttons_cantidad:hover {
  background-color: #a82034;
}

.buttons_cantidad_layout {
  width: 30px;
  height: 30px;
  background-color: #e0324b;
  color: #fff;
  border: none;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4px;
  transition: all .3s ease;
}

.buttons_cantidad_layout:hover {
  background-color: #a82034;
}

.left {
  border-radius: 8px 0 0 8px;
}

.right {
  border-radius: 0 8px 8px 0;
}

.input_cantidad {
  width: 40px;
  height: 35px;
  text-align: center;
  font-size: 1.1rem;
  color: #5c5e60;
  font-weight: 600;
}

.input_cantidad_layout {
  width: 35px;
  height: 30px;
  text-align: center;
  font-size: 1rem;
  color: #5c5e60;
  font-weight: 600;
}
  
.button_agregar {
  background-color: #e0324b;
  border-radius: 8px;
  border: #e0324b;
  color: #fff;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  transition: all .3s ease;
  position: relative;
  z-index: 10000;
  font-size: .8rem;
}

.button_agregar:hover {
  background-color: #a82034;
}

.button_agregar_layout {
  background-color: #e0324b;
  border-radius: 8px;
  border: #e0324b;
  color: #fff;
  padding: 5px 15px;
  display: flex;
  font-size: .8rem;
  justify-content: center;
  align-items: center;
  gap: .3rem;
  text-transform: uppercase;
  font-weight: 600;
  transition: all .3s ease;
  position: relative;
  z-index: 10000;
}

.button_agregar_layout:hover {
  background-color: #a82034;
}


/* .visible {
  opacity: 1;
}

@keyframes moveRight {
  from { transform: translateX(150px); }
  to { transform: translateX(0px); }
} */





