.button_menu {
  transition: all .2s ease;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 6px;
  padding: 2px 6px;
  color: #6e6e6e;
  display: grid;
  place-items: center;
}

.button_menu:hover {
  background-color: #ececec;
}

.opcions_menu_sidebar:hover .span_menu{
  color: #f5f5f5;
}

.opcions_menu_sidebar:hover .icon_menu{
  color: #f5f5f5;
}

.icon_menu {
  color: #c5c8db;
}

.span_menu {
  color: #c5c8db;
  font-weight: 600;
  font-size: .95rem;
}

.span_submenu {
  color: #c5c8db;
  font-size: .85rem;
  padding-left: .7rem;
}

.contain_button_to_home {
  display: grid;
  place-items: center;
  padding-left: 2rem;
}

.content_button_to_home {
  display: grid;
  place-items: center;
  color: #555e6d;
  border: 1px solid #ddd;
  padding: 7px 10px;
  border-radius: 6px;
  transition: all .2s ease;
}

.content_button_to_home:hover {
  border: 1px solid #e0324b;
  color: #e0324b;
}

.home_span_oculto {
  display: none;
}

.active_to_home {
  position: absolute;
  left: 5.5rem;
  animation: home_animation .6s ease;
  font-size: 1rem;
  font-weight: 400;
}

@keyframes home_animation {
  0% {
    transform: translateX(-10px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}