.page_carrito {
  display: grid;
  place-items: center;
  background-color: #eaeded;
}

.container_page_carrito {
  width: 100%;
  padding: 7rem 2rem 4rem 2rem;
  gap: 2rem;
}

.container_carrito {
  display: grid;
  grid-template-columns: 1fr 380px;
  gap: 1.5rem;
}

.container_productos {
  background-color: #fff;
  border-radius: 6px;
  padding: 1rem 1.5rem;
  animation: productos .5s ease;
}
@keyframes productos {
  0% {
    transform: translateX(-80px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.content_producto_carrito {
  display: grid;
  grid-template-columns: 200px 1fr 200px;
  padding: 1.5rem 0;
  border-bottom: .5px solid #E7E7E7;

}


.container_actions {
  display: grid;
  grid-template-rows: 210px 200px;
  gap: 1.5rem;
}

/* @keyframes actions {
  0% {
    transform: translateY(300px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
} */


/************************ Container Info Producto ****************************/

.container_info_carrito {
  display: grid;
  justify-content: space-between;
  padding-left: 1rem;
}

.container_img_carrito_ {
  width: 200px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all .3s ease;
  border: 1px solid #fff;
  border-radius: 6px;
}

.img_carrito_ {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease;
}

.container_img_carrito_:hover {
  border: 1px solid #e0324b;
}

.title_producto_carrito {
  font-size: 1.4rem;
  color: #1E2022;
  text-transform: uppercase;
}

.medida_producto_carrito {
  font-size: 1.2rem;
  color: #e0324b;
}

.precio_producto_carrito {
  font-size: 1.5rem;
  color: #1E2022;
  font-weight: 600;
  text-align: end;
}

.content_actions_carrito {
  display: flex;
  gap: 1rem;
  align-items: end;
}

.cantidad_carrito {
}

.buttons_cantidad_carrito {
  width: 25px;
  height: 25px;
  background-color: #e0324b;
  color: #fff;
  border: none;
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  transition: all .3s ease;
}

.buttons_cantidad_carrito:hover {
  background-color: #a82034;
}

.left {
  border-radius: 8px 0 0 8px;
}

.right {
  border-radius: 0 8px 8px 0;
}

.input_cantidad_carrito {
  width: 30px;
  height: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #5c5e60;
  font-weight: 600;
}

.card-carrito {
  height: max-content;
}



.caracteristicas_llanta_carrito {
  font-size: .75rem;
  line-height: .2;
}

.span_llanta {
  font-size: .8rem;
}
  


/**********************************************************************************/


/*************************  Container precio total  ****************************/

.container_pagar {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 1rem 1.5rem;
}

.title_pagar {
  font-size: 1.4rem;
  color: #1E2022;
}

.subtotal {
  font-size: 1.05rem;
  font-weight: 400;
  color: #1E2022;
  letter-spacing: .1px;
}

.conten_pagar {
  display: flex;
  justify-content: space-between;
} 

.conten_img_pagar {
  display: flex;
  align-items: center;
}

.visa {
  width: 60px;
  padding-right: 10px;
}

.wompi {
  width: 60px;

}

.total_pagar {
  font-size: 1.5rem;
  color: #1E2022;
  font-weight: 600;
  text-align: end;
  padding-top: 10px;
  
}

.button_pagar {
  border: none;
  border-radius: 8px;
  width: 100%;
  padding: 8px 12px;
  background-color: #e0324b;
  color: #ffffff;
  transition: all .3s ease;
  font-weight: 600;
}

.button_pagar:hover {
  background-color: #a82034;
}


/**************************************************************************/

.container_recomendaciones {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 1rem 1.5rem;
}

.title_carrito {
  color: #1E2022;
  border-bottom: .5px solid #ddd;
  padding-bottom: 1rem;
  font-size: 1.5rem
}

.name_llanta_card {
  font-size: 1.4rem;
  text-transform: uppercase;
}