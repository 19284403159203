.content_wsapp {
  position: fixed;
  z-index: 9999;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
}

.img_wapp {
  width: 60px;
  cursor: pointer;
  z-index: 9999;
  
}

.p_wapp_oculto {
  animation: ws_animation_ocultar .3s ease;
  background-color: #4bc75a;
  color: #fff;
  padding: 8px 8px;
  border-radius: 10px 0 0 10px;
  font-size: 1rem;
  cursor: pointer;
  position: absolute;
  width: 155px;
  right: 3rem;
  opacity: 0;
}

.p_wapp {
  background-color: #4bc75a;
  color: #fff;
  padding: 8px 8px;
  border-radius: 10px 0 0 10px;
  font-size: 1rem;
  cursor: pointer;
  position: absolute;
  width: 155px;
  right: 3rem;
  animation: ws_animation .3s ease-in-out;
}

@keyframes ws_animation {
  0% {
    transform: translateX(20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes ws_animation_ocultar {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(20px);
    opacity: 0;
  }
}